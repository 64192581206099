<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <b-button variant="info" @click="editMeasurementUnit()">Создать шкалу диапазонов</b-button>
        <br /><br />
        <div v-if="error" class="alert alert-danger">
          {{error}}
        </div>
        <b-table striped hover :items="items" :fields="fields" ref="measurementUnitTable">
          <!-- A custom formatted column -->
          <template v-slot:cell(value)="data">
            <b>{{ data.item.value }}</b>
          </template>
          <template v-slot:cell(text)="data">
            <span v-html="data.item.text"></span>
          </template>
          <template v-slot:cell(reportText)="data">
            <span v-html="data.item.reportText"></span>
          </template>
          <template v-slot:cell(paramList)="data">
            <ul>
              <li v-for="p in data.item.params" :key="p.value"><b-link :to="'/analitedit/' + p.value">{{ p.text }}</b-link></li>
            </ul>
          </template>

          <template v-slot:cell(buttons)="data">
            <h3>
              <nobr>
                <b-link @click="blockUnblockScales(data.item.value, data.item.isBlocked)">
                  <b-icon variant="info" :icon="data.item.isBlocked ? 'toggle-off' : 'toggle-on'" size="lg"></b-icon>
                </b-link>

                <b-link @click="editMeasurementUnit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                &nbsp;
                <b-link @click="deleteMeasurementUnit(data.item.value, data.item.text)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>
        </b-table>
        <b-modal id="measurementUnitModal" hide-footer size="lg" title="Шкала">
          <ScaleRangeValueEdit @save-success="afterSave" v-bind:error="error" v-bind:current-measurement-unit="currentMeasurementUnit"></ScaleRangeValueEdit>
        </b-modal>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import {host} from '@/constants'
import ScaleRangeValueEdit from "./ScaleRangeValueEdit";
export default {

  name: "ScaleRangeValueList",
  components: {
    ScaleRangeValueEdit,
    Loader
  },
  data() {
    return {
      fields: [
        {
          key: 'value',
          label: 'Id',
          sortable: true
        },
        {
          key: 'text',
          label: 'Наименование',
          sortable: true
        },
        // {
        //   key: 'reportText',
        //   label: 'Фраза для отчета врача',
        //   sortable: true
        // },
        {
          key: 'paramList',
          label: 'Аналиты',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentMeasurementUnit: null
    }
  },
  methods: {
    deleteMeasurementUnit(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить шкалу "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/scaleranges/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.value !== paramId)

              } else {

                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editMeasurementUnit(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('measurementUnitModal')
    },
    fetchData() {
      fetch(host + '/rest/scaleranges/with-params')
          .then(response => response.json())
          .then(json => {
            this.items = json.options
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('measurementUnitModal')
      this.loading = true
      this.fetchData()
    },
    async blockUnblockScales(scaleId, isBlocked) {

      this.loading = true;
      let response = await fetch(host + '/rest/scaleranges/' + scaleId + '/block/' + !isBlocked, {
        method: 'GET',
        credentials: 'include',
      })
      let json = await response.json()
      try {
        if (json.resultCode == 0) {
          await this.fetchData()
        } else {
          this.error = json.message;
        }
      } catch(error) {
        this.error = error
        console.log(JSON.stringify(error))
      }
      this.loading = false
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>